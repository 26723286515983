import React from "react";
import { AppSettings } from "../App";
import { InstructorProfileReviewModel } from "./models/InstructorProfileReviewModel";
import { Review } from "./Review";

const reviewsPerRow = 4;
interface IProps {
    settings: AppSettings;
    reviews: InstructorProfileReviewModel[];
}
interface IState {
    reviews: InstructorProfileReviewModel[][];
}
export class ReviewsSection extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        var reviewsArray: InstructorProfileReviewModel[][] = [];
        for (let index = 0; index < props.reviews.length; index += reviewsPerRow) {
            var subArray: InstructorProfileReviewModel[] = []
            for (let subIndex = index; subIndex < index + reviewsPerRow; subIndex++) {
                var element = props.reviews[subIndex];
                if (element != null) {
                    subArray.push(element);
                }
            }
            reviewsArray.push(subArray);
        }
        this.state = {
            reviews: reviewsArray
        };
    }
    render(): React.ReactNode {
        return (
            <>
                {this.state.reviews.map((row, index) => (
                    <div key={index} className={'card-group w-md-' + ((row.length / reviewsPerRow) * 100)}>
                        {row.map(review => (
                            <Review settings={this.props.settings} review={review} key={review.id}  />
                        ))}
                    </div>
                ))}
            </>
        )
    }
}