import React from "react";
import { AppSettings } from "../App";
import { InstructorListModel } from "./InstructorListModel";
import { Pagination } from "../common/Pagination";
import { InstructorListRow } from "./InstructorListRow";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


interface IProps {
    settings: AppSettings;
}
interface IState {
    items: InstructorListModel[];
    maxPages: number;
    totalCount: number;
    pageIndex: number;
    errorMessage?: string;
    startCarouselSlideNumberShown: number;
    endCarouselSlideNumberShown: number;
}

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export class InstructorList extends React.Component<IProps, IState> {
    pageSize: number = 5;
    Carousel: Carousel | null = null;
    constructor(props: IProps) {
        super(props);
        this.state = {
            items: [],
            maxPages: 1,
            totalCount: 0,
            pageIndex: 1,
            startCarouselSlideNumberShown: 0,
            endCarouselSlideNumberShown: 0
        }
        this.getMoreInstructors = this.getMoreInstructors.bind(this);
    }
    componentDidMount() {
        this.getInstructors(1);
    }

    render() {
        return (
            <div style={{ userSelect: "none" }}>
                {this.state.items.length > 0 && this.state.errorMessage == null &&
                    <>
                        <Carousel ref={(el) => (this.Carousel = el)} responsive={responsive} swipeable={true}
                            draggable={true} arrows={false} autoPlay={true} autoPlaySpeed={4000} containerClass="instructor-carousel"
                            showDots={false} partialVisible={true} afterChange={(previous, state) => this.afterCarouselChange(previous, state)}>
                            {this.state.items.map(row => (
                                <InstructorListRow key={row.inId} instructor={row} settings={this.props.settings} />
                            ))}
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                <button disabled={this.state.pageIndex === this.state.maxPages} className="btn btn-primary btn-lg" onClick={this.getMoreInstructors}>{this.state.pageIndex === this.state.maxPages ? 'No more to load' : 'Load more'}</button>
                            </div>
                        </Carousel>
                        <p className="text-center">Showing {this.state.startCarouselSlideNumberShown}{this.state.endCarouselSlideNumberShown == this.state.startCarouselSlideNumberShown ? '': '-' + this.state.endCarouselSlideNumberShown} of {this.state.totalCount}</p>
                    </>
                }

                {this.state.errorMessage != null &&
                    <strong style={{ color: 'red' }}>{this.state.errorMessage}</strong>
                }
            </div>
        );
    }

    private afterCarouselChange(previousSlide: number, carouselState: any) {
        this.setState(prevState => {
            return {
                startCarouselSlideNumberShown: carouselState.currentSlide + 1,
                endCarouselSlideNumberShown: carouselState.currentSlide + carouselState.slidesToShow
            };
        });
    }


    private getInstructors(pageIndex: number) {
        var url = this.props.settings.apiBaseUrl + '/api/instructorpublicprofile';
        var queryString = '?pageIndex=' + pageIndex + '&pageSize=' + this.pageSize;
        if (this.props.settings.locationId != null) {
            queryString += '&loId=' + this.props.settings.locationId;
        }
        fetch(url + queryString)
            .then(resp => resp.json())
            .then(
                (result) => {
                    var data = result as Pagination<InstructorListModel>;
                    this.setState({
                        items: Array.prototype.concat(this.state.items, data.items),
                        maxPages: data.maxPages,
                        totalCount: data.totalRecordsCount + 1,
                        pageIndex: data.pageIndex,
                    });
                    if (this.Carousel != null) {
                        this.Carousel.state.totalItems = this.state.items.length + 1;
                        this.afterCarouselChange(0, this.Carousel.state);
                    }
                },
                (error) => {
                    this.setState({
                        errorMessage: error.message
                    });
                }
            );
    }

    private getMoreInstructors() {
        this.getInstructors(this.state.pageIndex + 1);
    }
}