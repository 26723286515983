import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { AppSettings } from "../App";
import { InstructorProfileReviewModel } from "./models/InstructorProfileReviewModel";
import { NullableApiImage } from "../common/NullableApiImage";

interface IProps {
    settings: AppSettings;
    review: InstructorProfileReviewModel;
    style?: React.CSSProperties
}

export class Review extends React.Component<IProps> {
    stars: number[] = [];
    constructor(props: IProps) {
        super(props);
        for (let i = 0; i < 5; i++) {
            this.stars.push(i);
        }
    }
    render() {
        return (
            <div className="card mb-3" style={this.props.style}>
                <NullableApiImage settings={this.props.settings} imgFileName={this.props.review.photoFileName} imgClassName="card-img-top" imageTitle="Review"/>
                <div className="card-body">
                    <h5 className="card-title">{this.props.review.name}</h5>
                    <pre className="card-text wrapped">{this.props.review.text}</pre>
                    <p className="card-text text-primary">
                        {this.stars.map(star => (
                            <FontAwesomeIcon icon={faStar} key={star}></FontAwesomeIcon>
                        ))}
                    </p>
                </div>
            </div>
        )
    }
}