export class TransmissionStringFromArray {
    public static Get(transmissions: string[]) {
        if (transmissions.length === 1) {
            return transmissions[0];
        }
        else if (transmissions.length === 2) {
            return transmissions[0] + ' & ' + transmissions[1];
        }
        else {
            return '';
        }
    }
}