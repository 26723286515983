import React from "react";
import { AppSettings } from "../App";
import { InstructorProfilePriceModel } from "./models/InstructorProfilePriceModel";

interface IProps {
    settings: AppSettings;
    prices: InstructorProfilePriceModel[];
}
export class PricesSection extends React.Component<IProps> {
    render() {
        return (
            <>
                {this.props.prices.map(transmission => (
                    <div className="card" key={transmission.transmissionName}>
                        <h4 className="card-header">Prices for <strong>{transmission.transmissionName}</strong> transmission</h4>
                        <div className="card-body">
                            <table className="table">
                                {transmission.categories.map(category => (
                                    <tbody key={category.categoryName}>
                                        <tr className="table-primary">
                                            <th>{category.categoryName}</th>
                                            <th>Hours</th>
                                            <th>Price (£)</th>
                                        </tr>
                                        {category.packagesOffered.map((pricePackage, index) => (
                                            <tr key={index}>
                                                <td>{pricePackage.lpmDescription}</td>
                                                <td>{pricePackage.lpmHours}</td>
                                                <td>{pricePackage.lppPrice}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={3}></td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                ))}
            </>
        )
    }
}