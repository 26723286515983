import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const targets = document.querySelectorAll('.iw-root');

Array.prototype.forEach.call(targets, target => {
  const id = target.dataset.id;
  const settings = (window as any).iwSettings[id];
  ReactDOM.render(
    <React.StrictMode>
        <App settings={settings} />
    </React.StrictMode>,
    target
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
