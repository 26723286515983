import React from 'react';
import { InstructorList } from './instructor-list/InstructorList';
import { InstructorPage } from './instructor-page/InstructorPage';
import './styles.scss';

export interface AppSettings {
  hostBaseUrl: string;
  apiBaseUrl: string;
  isInstructorPage: boolean;
  instructorId?: number;
  locationId?: number;
}

interface IProps {
  settings: AppSettings
}
interface IState {
  settings: AppSettings
}

class App extends React.Component<IProps, IState> {
  render() {
    return (
      <div>
        {this.props.settings.isInstructorPage === false &&
          <InstructorList settings={this.props.settings} />
        }
        {this.props.settings.isInstructorPage === true && this.props.settings.instructorId !== undefined &&
          <InstructorPage settings={this.props.settings} inId={this.props.settings.instructorId}/>
        }
      </div>

    );
  }
}

export default App;