import React from "react";
import { AppSettings } from "../App";
import { ImageUrl } from "../common/ImageUrl";
import { TransmissionStringFromArray } from "../common/TransmissionStringFromArray";
import { InstructorProfileModel } from "./models/InstructorProfileModel";
import { Review } from "./Review";
import studentPic1 from "./images/student_pic_1.jpg";
import instructorVehiclePic from "./images/instructor_vehicle.png";
import studentPic2 from "./images/student_pic_2.jpg";
import { BookDrivingLessonsButton } from "../common/BookDrivingLessonsButton";
import { NullableApiImage } from "../common/NullableApiImage";
import { QuestionsAndAnswersSection } from "./QuestionAndAnswersSection";
import { ReviewsSection } from "./ReviewsSection";
import { PricesSection } from "./PricesSection";
import { ServicesSection } from "./ServicesSection";

interface IProps {
    inId: number;
    settings: AppSettings;
}
interface IState {
    instructor?: InstructorProfileModel;
    errorMessage?: string;
}
export class InstructorPage extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            instructor: undefined
        }
    }
    componentDidMount() {
        this.loadPage();
    }
    loadPage() {
        if (this.props.inId != null) {
            var url = this.props.settings.apiBaseUrl + '/api/instructorpublicprofile/' + this.props.inId;
            fetch(url)
                .then(resp => resp.json())
                .then(
                    (result) => {
                        var data = result as InstructorProfileModel;
                        this.setState({
                            instructor: data
                        })
                    },
                    (error) => {
                        this.setState({
                            errorMessage: error.message
                        });
                    }
                );
        }
    }
    render(): React.ReactNode {
        if (this.state.instructor !== undefined && this.state.errorMessage == null) {
            return (
                <div style={{ maxWidth: '1080px', paddingLeft: 'var(--bs-gutter-x, .75rem)', paddingRight: 'var(--bs-gutter-x, .75rem)' }} className="py-5 mx-auto">
                    <div className="row bg-primary mb-4">
                        <div className="col-md text-center mb-md-0 mb-3">
                            <NullableApiImage settings={this.props.settings} imgFileName={this.state.instructor.profilePhotoFileName} imgClassName="img-fluid" imageTitle="Profile" />
                        </div>
                        <div className="col-md text-center py-2">
                            <h2 className="text-white">Elite Driving Instructor</h2>
                            {this.state.instructor.namePhotoFileName != null &&
                                <img src={ImageUrl.Get(this.props.settings.apiBaseUrl, this.state.instructor.namePhotoFileName)} className="img-fluid mb-3" alt="..." />
                            }
                            {this.state.instructor.namePhotoFileName == null &&
                                <p><strong>{this.state.instructor.fullName}</strong></p>
                            }
                            <BookDrivingLessonsButton url={this.props.settings.apiBaseUrl} type="secondary" />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-8">
                            <pre className="wrapped">{this.state.instructor.biography}</pre>
                        </div>
                        <div className="col-md-4 text-center">
                            {this.state.instructor.featuredReview != null &&
                                <Review review={this.state.instructor.featuredReview} settings={this.props.settings} />}
                            {this.state.instructor.featuredReview == null &&
                                <p>No featured review found</p>}
                            <BookDrivingLessonsButton url={this.props.settings.apiBaseUrl} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">The area {this.state.instructor.firstName} covers for driving lessons</h5>
                                    <pre className="card-text wrapped">{this.state.instructor.areaCoveredText}</pre>
                                </div>
                                <NullableApiImage settings={this.props.settings} imgFileName={this.state.instructor.areaCoveredPhotoFileName} imgClassName="card-img-bottom" imageTitle="Area" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md">
                            <h4 className="text-center">{this.state.instructor.firstName} Teaches <strong>{TransmissionStringFromArray.Get(this.state.instructor.transmissions)}</strong> driving lessons</h4>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <NullableApiImage settings={this.props.settings} imgFileName={this.state.instructor.vehicle.photoFileName} imgClassName="img-fluid rounded-start" imageTitle="Vehicle" />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h5 className="card-title">{this.state.instructor.vehicle.title}</h5>
                                            <p className="card-text">{this.state.instructor.vehicle.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md">
                            <h5 className="text-primary text-center fw-bold">Learn to drive with 100% confidence that you will make new progress on every lesson - Even if you struggle learning new things</h5>
                        </div>
                    </div>
                    <div className="row g-4 mb-4">
                        <div className="col-md card px-0">
                            <img src={this.props.settings.hostBaseUrl + studentPic1} alt="Student with instructor in tuition car" className="card-img-top" />
                            <div className="card-body">
                                <p className="card-title">Review, discuss and agree lesson plan with the student &amp; set clearly defined goals</p>
                            </div>
                        </div>
                        <div className="col-md card px-0">
                            <img src={this.props.settings.hostBaseUrl + instructorVehiclePic} alt="Tuition Vehicle driving on road" className="card-img-top" />
                            <div className="card-body">
                                <p className="card-title">We select a suitable practice area to help the student make progress towards achieving their goal</p>
                            </div>
                        </div>
                        <div className="col-md card px-0">
                            <img src={this.props.settings.hostBaseUrl + studentPic2} alt="Student with instructor in tuition car" className="card-img-top" />
                            <div className="card-body">
                                <p className="card-title">Encourage reflection and involvement in the learning process to develop the knowledge and skills of the student</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <BookDrivingLessonsButton url={this.props.settings.apiBaseUrl} />
                        </div>
                    </div>
                    {this.state.instructor.answers.length > 0 &&
                        <div className="row mb-4">
                            <div className="col-md">
                                <h4>We asked {this.state.instructor.firstName} some questions about being a driving instructor</h4>
                                <QuestionsAndAnswersSection settings={this.props.settings} answers={this.state.instructor.answers} />
                            </div>
                        </div>}
                    {this.state.instructor.reviews.length > 0 &&
                        <div className="row mb-4">
                            <div className="col-md">
                                <ReviewsSection settings={this.props.settings} reviews={this.state.instructor.reviews} />
                            </div>
                        </div>}
                    {(this.state.instructor.answers.length > 0 || this.state.instructor.reviews.length > 0) &&
                        <div className="row mb-4">
                            <div className="col-md text-center">
                                <BookDrivingLessonsButton url={this.props.settings.apiBaseUrl} />
                            </div>
                        </div>}
                    <div className="row mb-4">
                        <div className="col-md">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Taking driving lessons with {this.state.instructor.fullName}</h4>
                                    <pre className="card-text wrapped">{this.state.instructor.reasonsToPickText}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md">
                            <PricesSection settings={this.props.settings} prices={this.state.instructor.prices} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <BookDrivingLessonsButton url={this.props.settings.apiBaseUrl} />
                        </div>
                    </div>
                    {this.state.instructor.services.length > 0 &&
                        <div className="row mb-4">
                            <div className="col-md">
                                <div className="card">
                                    <div className="card-header">{this.state.instructor.firstName} also offers these services</div>
                                    <div className="card-body">
                                        <ServicesSection settings={this.props.settings} services={this.state.instructor.services} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.instructor.reviewUrl != null &&
                        <div className="row mb-4">
                            <div className="col-md text-center">
                                <a className="btn btn-primary text-white"
                                    href={this.state.instructor.reviewUrl} target="_blank" rel="noreferrer">Leave {this.state.instructor.firstName} a review</a>
                            </div>
                        </div>
                    }
                </div>
            )
        }
        else if (this.state.errorMessage != null) {
            return this.state.errorMessage;
        }
        else {
            return 'Loading...'
        }
    }
}