import React from "react";
import { AppSettings } from "../App";
import { NullableApiImage } from "../common/NullableApiImage";
import { InstructorProfileServiceModel } from "./models/InstructorProfileServiceModel";

interface IProps {
    settings: AppSettings;
    services: InstructorProfileServiceModel[];
}

export class ServicesSection extends React.Component<IProps> {
    render() {
        return (
            this.props.services.map(service => (
                <div className="card mb-3 w-100" key={service.id}>
                    <div className="row g-0">
                        <div className="col-md-4">
                            <NullableApiImage settings={this.props.settings} imgFileName={service.photoFileName}
                            imgClassName="img-fluid rounded-start" imageTitle="Service"/>
                    </div>
                        <div className="col-md-8">
                            <div className="card-body d-flex align-content-center">
                                <h5 className="card-title">{service.name}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            ))

        )
    }
}