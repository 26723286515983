import React from "react";
interface IProps {
    url: string;
    type?: string;
}
export class BookDrivingLessonsButton extends React.Component<IProps> {
    render() {
        return (
            <a className={'btn btn-' + (this.props.type != null ? this.props.type : 'primary') + ' text-white'}
                href={this.props.url} target="_blank" rel="noreferrer">Book your driving lessons</a>
        )
    }
}