import React from "react";
import { AppSettings } from "../App";
import { ImageUrl } from "./ImageUrl";

interface IProps {
    settings: AppSettings;
    imgFileName?: string;
    imgClassName?: string;
    imageTitle?: string;
}

export class NullableApiImage extends React.Component<IProps> {
    render() {
        return (
            <>
                {this.props.imgFileName != null &&
                    <img src={ImageUrl.Get(this.props.settings.apiBaseUrl, this.props.imgFileName)} 
                    className={this.props.imgClassName != null ? this.props.imgClassName : ''} alt={this.props.imageTitle} />}
                {this.props.imgFileName == null &&
                    <span>No {this.props.imageTitle != null ? this.props.imageTitle + ' ' : ''}Photo Found</span>
                }
            </>

        )
    }
}