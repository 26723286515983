import React from "react";
import { AppSettings } from "../App";
import { InstructorProfileAnswerModel } from "./models/InstructorProfileAnswerModel";

interface IProps {
    settings: AppSettings;
    answers: InstructorProfileAnswerModel[];
}

export class QuestionsAndAnswersSection extends React.Component<IProps> {
    render() {
        return (
            <ul className="list-group">
                {this.props.answers.map(row => (
                    <li className="list-group-item" key={row.inaId}>
                        <p><strong>{row.inqQuestion}</strong></p>
                        <p className="fst-italic">{row.inaAnswer}</p>
                    </li>
                ))}
            </ul>
        )
    }
}