import React, { createRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AppSettings } from "../App";
import { InstructorListModel } from "./InstructorListModel";
import { InstructorPage } from "../instructor-page/InstructorPage";
import { TransmissionStringFromArray } from "../common/TransmissionStringFromArray";
import { NullableApiImage } from "../common/NullableApiImage";
import { ImageUrl } from "../common/ImageUrl";

interface IProps {
    instructor: InstructorListModel;
    settings: AppSettings;
}
interface IState {
    show: boolean
}


export class InstructorListRow extends React.Component<IProps, IState> {
    ref = createRef<InstructorPage>();
    constructor(props: IProps) {
        super(props);
        this.state = {
            show: false
        };
    }
    private setShow(show: boolean) {
        if (show === true) {
            this.ref.current?.loadPage();
        }
        this.setState({
            show: show
        });
        console.trace(show);
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.log(error, errorInfo);
    }

    render() {
        return (
            <>
                <div className="card w-100 h-100 d-grid"
                    style={
                        {
                            backgroundImage: this.props.instructor.profilePhotoFileName != null ? 'url(' + ImageUrl.Get(this.props.settings.apiBaseUrl, this.props.instructor.profilePhotoFileName) + ')' : undefined,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }
                    }>
                    {this.props.instructor.profilePhotoFileName == null &&
                        <span style={{justifySelf: 'center', alignSelf:'end'}}>No Profile Photo Found</span>
                    }
                    <button onClick={() => this.setShow(true)} className="btn btn-primary btn-lg" style={
                        {
                            marginBottom: "30px",
                            justifySelf: 'center', 
                            alignSelf:'end'
                        }
                    }>View Profile</button>
                </div>
                <Modal show={this.state.show} onHide={() => this.setShow(false)} dialogClassName="modal-90w">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.instructor.fullName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InstructorPage settings={this.props.settings} inId={this.props.instructor.inId} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setShow(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}